import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <div className="inner">
                <div className="info">
                    <ul className="links">
                        <li><a href="/">이용약관</a></li>
                        <li><a href="/">개인정보처리방침</a></li>
                        <li><a href="http://pf.kakao.com/_gdGNG">고객센터</a></li>
                    </ul>
                    <div className="footer-logo mobile">
                        <a href="/" className="logo"><img src={process.env.PUBLIC_URL + '/img/logo/logo.png'} alt="나비잠"/></a>
                    </div>
                    {/*<div className="detail">
                        <span>상호명 나비잠 안마의자</span>
                        <span>대표 김유정</span>
                        <span>사업자 등록번호 862-59-00857 <a href="/">사업자정보확인</a></span>
                        <span>문의번호 010-7748-5916 </span>
                    </div>
                    <div className="detail">
                        <span className="address">주소 서울특별시 종로구 종로 19, 지하층 B226-2호(종로 1가, 르메이에종로타운1)</span>
                        <span>이메일 gywjdskql5915@gmail.com</span>
                    </div>*/}
                    <div className="detail">
                        <span>상호명 나비잠 안마의자 역삼점</span>
                        <span>대표 노해미</span>
                        <span>사업자 등록번호 424-04-03064 <a href="/">사업자정보확인</a></span>
                        <span>문의번호 010-7748-5916 </span>
                    </div>
                    <div className="detail">
                        <span className="address">주소 서울특별시 강남구 테헤란로21길 5, 지하1층 (역삼동) </span>
                        <span>이메일 gywjdskql5915@gmail.com</span>
                    </div>
                    <div className="detail detail-last">
                        <span>광고 매체 문의 gywjdskql5915@gmail.com</span>
                    </div>
                    <div className="copyright">Copyright © 나비잠 안마의자 2023 All Rights Reserved.</div>
                </div>
                <div className="footer-logo">
                    <a href="/" className="logo"><img src={process.env.PUBLIC_URL + '/img/logo/logo_full.png'} alt="나비잠 안마의자"/></a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
