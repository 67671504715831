import React, { useState, useEffect, useRef } from 'react';
import Header from '../../Components/Header';
import Modal from 'react-bootstrap/Modal';
import {Link} from "react-router-dom";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoInformationCircleOutline } from "react-icons/io5";
import { RoomName, RankList, RankPrice, roomImgDict, RoomRank, RankColor, RoomExplain, branchDict, roomList, numList, saleDict, BankCode } from "../../Pages/config.js"
import { TbStar, TbStarHalf } from "react-icons/tb";
import { BrowserView, MobileView } from 'react-device-detect'
import * as moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select'

import {
  PaymentWidgetInstance,
  loadPaymentWidget,
  ANONYMOUS,
} from "@tosspayments/payment-widget-sdk"
import { nanoid } from "nanoid";

const Booking = () => {
    console.log(sessionStorage.getItem('branch'))
    const location = useLocation();
    if (sessionStorage.getItem('branchList')==null || sessionStorage.getItem('logout')==true){
        window.location.href = "/admin"
        sessionStorage.setItem("logout", true)
    }
    const navigate = useNavigate();
    const [check5, setCheck5] = useState(false);
    const [check10, setCheck10] = useState(false);
    const [check15, setCheck15] = useState(false);
    const [check20, setCheck20] = useState(false);
    const [show, setShow] = useState(false);
    const [showA, setShowA] = useState(false);
    const [showD, setShowD] = useState(false);
    const [showT, setShowT] = useState(false);
    const [showC, setShowC] = useState(false);
    const [room, setRoom] = useState("1");
    const [name, setName] = useState(null);
    const [bankCode, setBankCode] = useState(null);
    const [accNum, setAccNum] = useState(null);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [showAcc, setShowAcc] = useState(false);
    const [showPhone, setShowPhone] = useState(false);
    const [temp, setTemp] = useState(null);
    const [control, setControl] = useState(null);
    const [depth1, setDepth1] = useState(null);
    const [depth2, setDepth2] = useState(null);
    const [depth3, setDepth3] = useState(null);

    const [title, setTitle] = useState("도어락제어");

    const [id, setID] = useState(null);
    console.log(sessionStorage.getItem('branch'))
    const [branch, setBranch] = useState(sessionStorage.getItem('branch'));
    console.log(branch)
    console.log(JSON.parse(sessionStorage.getItem('branchList')))
    const [branchList, setBranchList] = useState(JSON.parse(sessionStorage.getItem('branchList')));
    console.log("branchList", branchList)
    console.log(branchList)
    const handleClose = () => {setShow(false); setPhone(""); setShowPhone(false);setShowAcc(false)};
    const handleShow = (event) => {
        console.log("ROOM::",event.target.id);
        setRoom(event.target.id);
        setShow(true);
    };
    const handleCloseA = () => {setShowA(false); setPhone(""); setShowPhone(false);setShowAcc(false)};
    const handleShowA = (event) => {
        setShowA(true);
    };

    const handleCloseD = () => {setShowD(false);};
    const handleShowD = (event) => {
        setShowD(true);
    };
    const handleCloseT = () => {setShowT(false);};
    const handleShowT = (event) => {
        setShowT(true);
    };
    const handleCloseC = () => {setShowC(false);};
    const handleShowC = (event) => {
        setShowC(true);
    };

    const handleBranch = (event) => {
        console.log("handleBranch")
        console.log(event.target.id)
        setBranch(event.target.id);
        sessionStorage.setItem('branch', event.target.id)
    };


    const today = new Date();
    const [date, setDate] = useState(today);
    const [hourNow, setHourNow] = useState(today.getHours());
    const [hour, setHour] = useState(today.getHours());
    const [day, setDay] = useState(0);
    const [select, setSelect] = useState([]);
    const [mapping, setMapping] = useState({});
    const [detail, setDetail] = useState(null);
    const [reservedAll, setReservedAll] = useState({
        "booking": [],
        "bookingList": [],
        "paidList": [],
        "paid": []
    });
    const [reserved, setReserved] = useState({
        "booking": [],
        "bookingList": [],
        "paidList": [],
        "paid": []
    });

    const [start, setStart] = useState("9_9_9");
    const [end, setEnd] = useState("0_0_0");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [step, setStep] = useState(0);
    const [price, setPrice] = useState(0);
    const [initprice, setInitPrice] = useState(0);

    function getReservedBlock() {
        if (sessionStorage.getItem('branchList')==null|| sessionStorage.getItem('logout')==true){
            window.location.href = "/admin"
            sessionStorage.setItem("logout", true)
        }
        else{
            console.log(process.env.REACT_APP_serviceUrl + `/admin/reservations?branch=${branch}&base_date=${moment(date, 'YYYYMMDD').format().split('T')[0].replaceAll("-","")}&base_hour=${hour}`)
            fetch(process.env.REACT_APP_serviceUrl + `/admin/reservations?branch=${branch}&base_date=${moment(date, 'YYYYMMDD').format().split('T')[0].replaceAll("-","")}&base_hour=${hour}`, {
                credentials: 'include',
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
                }).then(res => res.json())
            .then(json => {
                if (json.status=="success"){
                    const data = json.data
                    setReservedAll(data)
                    console.log("getReservedBlock")
                    let mapp = {}
                    let paid = []
                    for (var res=0; res<data["paid"].length; res++) {
                        paid.push(data["paid"][res]["time"])
                        mapp[data["paid"][res]["time"]] = data["paid"][res]["id"]
                    }
                    data["paidList"] = paid
                    let booking = []
                    for (var res=0; res<data["booking"].length; res++) {
                        booking.push(data["booking"][res]["time"])
                        mapp[data["booking"][res]["time"]] = data["booking"][res]["id"]
                    }
                    data["bookingList"] = booking
                    setMapping(mapp)
                    setReserved(data)
                    console.log(data)
                    console.log(data["paid"])
                }
                else {
                    sessionStorage.setItem("logout", true)
                    window.location.href = "/admin"
                }
                console.log(json.data);

            })
        }
    }

    function getDetailBlock() {
        console.log(process.env.REACT_APP_serviceUrl + `/admin/reservation/${id}`)
        fetch(process.env.REACT_APP_serviceUrl + `/admin/reservation/${id}`, {
            credentials: 'include',
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            }).then(res => res.json())
            .then(json => {
                if (json.status=="success"){
                    setDetail(json.data)
                    console.log("getReservedBlock")
                    console.log(json.data)
                }
                else {
                    console.log(json.data);
//                    sessionStorage.setItem("logout", true)
//                    window.location.href = "/admin"
                }

            })
    }

    function reSendAlarm() {
        console.log(process.env.REACT_APP_serviceUrl + `/admin/alimtalk`)
        if (showPhone==false){
            setShowPhone(true)
        }
        else {

            fetch(process.env.REACT_APP_serviceUrl + `/admin/alimtalk`, {
                credentials: 'include',
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(
                    {
                    "payId": id,
                    "mobileNum": phone
                    }
                )
                }).then(res => res.json())
                .then(json => {
                        if (json.status=="success"){
                            window.alert("성공적으로 알림톡을 발송하였습니다.")
                        } else {
                            window.alert("알림톡 발송에 실패했습니다.")
                        }
                })
        }
    }

    function deleteBlock() {

        if (detail.dataType=="booking") {
            var warning = window.confirm("정말로 예약을 취소하시겠습니까?")
            if (warning){
            console.log(process.env.REACT_APP_serviceUrl + `/admin/reservation/${id}?bookingStatus=${detail.bookingStatus}`)
            fetch(process.env.REACT_APP_serviceUrl + `/admin/reservation/${id}?bookingStatus=${detail.bookingStatus}`, {
                credentials: 'include',
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json",
                },
                }).then(res => res.json())
                .then(json => {
                    if (json.status=="success"){
                        window.alert("성공적으로 취소되었습니다.")
                        setShowA(false);
                        window.location.reload();
                    } else {
                        window.alert("취소에 실패했습니다.")
                    }
                })
                }


        } else {
            if ((detail.paidMethod).includes("가상계좌")) {
                if (showAcc==false){
                    {/* window.alert("환불 받으실 계좌 정보를 입력해주세요.")*/}
                    setShowAcc(true)
                } else {
                    if (bankCode==null) {
                        window.alert("은행코드를 선택해주세요.")
                    } else if (accNum==null) {
                        window.alert("계좌번호를 입력해주세요.")
                    } else if (name==null) {
                        window.alert("성함을 입력해주세요.")
                    } else {
                        var warning = window.confirm("정말로 예약을 취소하시겠습니까?")
                        if (warning){
                            setShowAcc(true)
                            console.log(process.env.REACT_APP_serviceUrl + `/admin/reservation/${id}?refundReceiveAccountBank=${bankCode}&refundReceiveAccountNumber=${accNum}&refundReceiveAccountHolderName=${name}`)
                            fetch(process.env.REACT_APP_serviceUrl + `/admin/reservation/${id}?refundReceiveAccountBank=${bankCode}&refundReceiveAccountNumber=${accNum}&refundReceiveAccountHolderName=${name}`, {
                                credentials: 'include',
                                method: "DELETE",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                }).then(res => res.json())
                                .then(json => {
                                    if (json.status=="success"){
                                        window.alert("성공적으로 취소되었습니다.")
                                        setShowA(false);
                                        window.location.reload();
                                    } else {
                                        window.alert("취소에 실패했습니다.")
                                    }
                                })
                        }
                    }
                }
            }
            else {
                var warning = window.confirm("정말로 예약을 취소하시겠습니까?")
                if (warning){
                console.log(process.env.REACT_APP_serviceUrl + `/admin/reservation/${id}`)
                fetch(process.env.REACT_APP_serviceUrl + `/admin/reservation/${id}`, {
                    credentials: 'include',
                    method: "DELETE",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    }).then(res => res.json())
                    .then(json => {
                        if (json.status=="success"){
                            window.alert("성공적으로 취소되었습니다.")
                            setShowA(false);
                            window.location.reload();
                        } else {
                            window.alert("취소에 실패했습니다.")
                        }
                    })
                }
            }

        }

    }

    const deleteRoom = () => {
        fetch(process.env.REACT_APP_serviceUrl + "/reservation"
        , {
            credentials: 'include',
            method: "DELETE",
            headers: {
                  "Content-Type": "application/json",
            },
            }).then(res => res.json())
            .then(json => {
                console.log(json)
                if (json.status=="success") {
                    console.log("예약내역 삭제 성공")
                } else {
                    console.log("예약내역 삭제 실패")
                }

            })
    }

    useEffect(() => {
        console.log(window.location.href)
        console.log(window.location.href.slice(-4,))
        getReservedBlock()

    }, [])


    useEffect(() => {
        getReservedBlock()
    }, [hour, date, branch])



    const addDate = (event) => {
        setStart("9_9_9")
        setEnd("0_0_0")
        console.log("day", day)
        date.setDate(date.getDate() + 1)
        setDate(prev => date);
        setDay(prev => prev+1)
        if (day==-1){
            setHour(today.getHours())
        }else {
            setHour(0)
        }
    };
    const subDate = (event) => {
        setStart("9_9_9")
        setEnd("0_0_0")
        console.log("day", day)
        date.setDate(date.getDate() - 1)
        setDate(prev => date)
        setDay(prev => prev-1)
        if (day==1){
            setHour(today.getHours())
        }else {
            setHour(0)
        }
    };

    const handleHour = (event) => {
        setStart("9_9_9")
        setEnd("0_0_0")
        if (event.target.id=="-2"){
            setHour(prev=>prev-2)
        }else if (event.target.id=="-1"){
            setHour(prev=>prev-1)
        }else if (event.target.id=="+1"){
            setHour(prev=>prev+1)
        }else if (event.target.id=="+2"){
            setHour(prev=>prev+2)
        }
    };

    const addComma = (price) => {
        let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return returnString;
        }


    function calcPrice() {
        const hour = parseInt(end.split("_")[1]) - parseInt(start.split("_")[1])
        const minutes = parseInt(end.split("_")[2]) - parseInt(start.split("_")[2])
        const time = hour*60 + (minutes+1)*10
        console.log(end.split("_")[1])
        console.log(start.split("_")[1])
        console.log(end.split("_")[2])
        console.log(start.split("_")[2])
        console.log("hour: ", hour)
        console.log("minutes: ", minutes)
        console.log("time: ", time)
        const nhour = Math.floor(time/60)
        const nhalf = Math.floor((time-60*nhour)/30)
        const nmin = Math.floor((time-60*nhour-30*nhalf)/10)
        const roomPrice = nhour * RankPrice[RoomRank[branch][start.split("_")[0]]][60] + nhalf * RankPrice[RoomRank[branch][start.split("_")[0]]][30] + nmin * RankPrice[RoomRank[branch][start.split("_")[0]]][10]
        if (check5){
            setPrice(roomPrice*0.95)
        } else if (check10) {
            setPrice(roomPrice*0.90)
        }
        else {
            setPrice(roomPrice)
        }
        setInitPrice(roomPrice)
    }

    const handleSelect = (event) => {
        console.log("handleSelect")
        console.log(event.target.id)
        console.log(reserved)
        console.log(mapping)
        if (reserved["paidList"].includes(event.target.id)) {
            for (var res=0; res<reserved["paidList"].length; res++) {
                console.log(reserved["paid"][res])
                console.log(reserved["paid"][res]["time"])
                if (reserved["paid"][res]["time"]==event.target.id){
                    setID(mapping[event.target.id])
                }
            }
            console.log(mapping[event.target.id])
            console.log(id)
            setShowA(true)
        } else if (reserved["bookingList"].includes(event.target.id)) {
            for (var res=0; res<reserved["bookingList"].length; res++) {
                if (reserved["booking"][res]["time"]==event.target.id){
                    setID(mapping[event.target.id])
                }
            }
            setShowA(true)
        }
        else {
            const roomNum = parseInt((event.target.id).split("_")[0])
            const timeNum = parseInt((event.target.id).split("_")[1] + (event.target.id).split("_")[2])
            console.log(roomNum)
            if (start=="9_9_9"){
                setStart(event.target.id)
                setEnd(event.target.id)
            } else if (roomNum==start.split("_")[0]){
                if (end==start){
                    if (event.target.id>start){
                        let pass = false
                        for (var res=0; res<reserved["paidList"].length; res++) {
                            console.log("res:",reserved["paidList"][res],"--","start:",start,"--","end:",event.target.id)
                            if (reserved["paidList"][res]>start && reserved["paidList"][res]<event.target.id) {
                                pass=true
                            }
                        }
                        if (pass==false) {
                            for (var res=0; res<reserved["bookingList"].length; res++) {
                                console.log("res:",reserved["bookingList"][res],"--","start:",start,"--","end:",event.target.id)
                                if (reserved["bookingList"][res]>start && reserved["bookingList"][res]<event.target.id) {
                                    pass=true
                                }
                            }
                        }
                        if (pass==false) {
                            setEnd(event.target.id)
                        } else {
                            window.alert("이미 예약된 시간을 포함하여 예약하실수는 없습니다.")
                        }
                    } else {
                        setStart(event.target.id)
                        setEnd(event.target.id)
                    }
                } else {
                    setStart(event.target.id)
                    setEnd(event.target.id)
                }
            } else {
                setStart(event.target.id)
                setEnd(event.target.id)
            }
        }
    };

    useEffect(() => {
        calcPrice()
    }, [start, end])

    useEffect(() => {
        getDetailBlock()
    }, [id])

    const nextStep = (event) => {
        console.log(hour)
        let moveNext= false;
        if (start=="9_9_9" && end=="0_0_0") {
            window.alert("예약하고자 하는 방과 시간을 선택해주세요.")
        } else if (start.split("_")[1]=="1" && Math.ceil(today.getMinutes()/10)>=parseInt(start.split("_")[2]) && hour==today.getHours() && day==0) {
            console.log(today.getMinutes())
            console.log(Math.ceil(today.getMinutes()/10))
            console.log(parseInt(start.split("_")[2]))
            console.log(start)
            var warning = window.confirm("예약을 요청하신 시간이 이미 "+today.getMinutes()%10+"분 이상 지난 시간입니다. 그럼에도 예약을 진행하시겠습니까?")
            if (warning) {
                moveNext = true
            }
        } else {
            moveNext = true
        }
        if (moveNext) {
            let startTime = "";
            let endTime = "";
            console.log("test: start")
            console.log((hour+parseInt(start.split('_')[1])-1))
            if ((hour+parseInt(start.split('_')[1])-1)<=23) {
                if ((hour+parseInt(start.split('_')[1])-1)>9){
                    startTime = moment(date, 'YYYYMMDD').format().split('T')[0].replaceAll("-","") + (hour+parseInt(start.split('_')[1])-1).toString() + ((parseInt(start.split('_')[2])-1).toString()+"0");
                } else {
                    startTime = moment(date, 'YYYYMMDD').format().split('T')[0].replaceAll("-","") + "0" + (hour+parseInt(start.split('_')[1])-1).toString() + ((parseInt(start.split('_')[2])-1).toString()+"0");
                }
            } else {
                const addStDate = new Date(date)
                addStDate.setDate(addStDate.getDate()+1)
                startTime = moment(addStDate, 'YYYYMMDD').format().split('T')[0].replaceAll("-","") + "0" + (hour+parseInt(start.split('_')[1])-1-24).toString() + ((parseInt(start.split('_')[2])-1).toString()+"0");
            }

            if ((hour+parseInt(end.split('_')[1])-1)<=23) {
                if ((hour+parseInt(end.split('_')[1])-1)>9){
                    endTime = moment(date, 'YYYYMMDD').format().split('T')[0].replaceAll("-","") + (hour+parseInt(end.split('_')[1])-1).toString() + ((parseInt(end.split('_')[2])-1).toString()+"0");
                } else {
                    endTime = moment(date, 'YYYYMMDD').format().split('T')[0].replaceAll("-","") + "0" + (hour+parseInt(end.split('_')[1])-1).toString() + ((parseInt(end.split('_')[2])-1).toString()+"0");
                }
            } else {
                const addDate = new Date(date)
                addDate.setDate(addDate.getDate()+1)
                endTime = moment(addDate, 'YYYYMMDD').format().split('T')[0].replaceAll("-","") + "0" + (hour+parseInt(end.split('_')[1])-1-24).toString() + ((parseInt(end.split('_')[2])-1).toString()+"0");
            }

            setStartTime(startTime)
            setEndTime(endTime)

            console.log("startTime:",startTime)
            console.log("endTime:",endTime)
            console.log({
                    "branch": branch,
                    "room": start.split("_")[0],
                    "start_date_time": startTime,
                    "end_date_time": endTime,
                    })


                setStep(prev => prev+1)
                navigate( '/admin/info',
                { state: {
                          start:start,
                          end:end,
                          date:date,
                          startTime:startTime,
                          endTime:endTime,
                          price:price,
                          name:"",
                          email:"",
                          phone:"",
                          branch:branch,
                          branchList:JSON.parse(sessionStorage.getItem('branchList'))
                            } } )

            }
    }

    const handleDepth1 = (e) => {
        console.log(control)
        console.log(e.target.value)
        console.log(control[e.target.value])
        if (control[e.target.value].id==null) {
            setDepth2(null)
            setDepth3(null)
            setDepth1(e.target.value)
            setTitle(control[e.target.value]['name'])
        } else {
            fetch(process.env.REACT_APP_serviceUrl + "/admin/airconditioner/"+control[e.target.value].id, {
                credentials: 'include',
                method: "POST",
                headers: {
                      "Content-Type": "application/json",
                },
                }).then(res => res.json())
                .then(json => {
                    if (json.status=="success") {
                        window.alert("냉난방기가 조정되었습니다.")
                    } else {
                        window.alert(json.message)
                    }
                }
            )
        }
    }

    const handleDepth2 = (e) => {
        console.log(control)
        console.log(control[depth1])
        console.log(control[depth1]['children'][e.target.value])
        if (control[depth1]['children'][e.target.value].id==null) {
            setDepth3(null)
            setDepth2(e.target.value)
            setTitle(control[depth1]['name'] + "-" + control[depth1]['children'][e.target.value]['name'])
        } else {
            fetch(process.env.REACT_APP_serviceUrl + "/admin/airconditioner/"+control[depth1]['children'][e.target.value].id, {
                credentials: 'include',
                method: "POST",
                headers: {
                      "Content-Type": "application/json",
                },
                }).then(res => res.json())
                .then(json => {
                    if (json.status=="success") {
                        window.alert("냉난방기가 조정되었습니다.")
                    } else {
                        window.alert(json.message)
                    }
                }
            )

        }
    }

    const handleDepth3 = (e) => {
        console.log(control)
        console.log(control[depth1])
        console.log(control[depth1]['children'][depth2])
        console.log(control[depth1]['children'][depth2]['children'])
        console.log(control[depth1]['children'][depth2]['children'][e.target.value])
        if (control[depth1]['children'][depth2]['children'][e.target.value].id==null) {
            setDepth3(e.target.value)
            setTitle(control[depth1]['name'] + "-" + control[depth1]['children'][depth2]['name'] + "-" + control[depth1]['children'][depth2]['children'][depth3]['name'])
        } else {
            fetch(process.env.REACT_APP_serviceUrl + "/admin/airconditioner/"+control[depth1]['children'][depth2]['children'][e.target.value].id, {
                credentials: 'include',
                method: "POST",
                headers: {
                      "Content-Type": "application/json",
                },
                }).then(res => res.json())
                .then(json => {
                    if (json.status=="success") {
                        window.alert("냉난방기가 조정되었습니다.")
                    } else {
                        window.alert(json.message)
                    }
                }
            )

        }
    }



    const openDoor = (e) => {
        console.log(e.target.value)
        fetch(process.env.REACT_APP_serviceUrl + "/admin/door/unlock/"+sessionStorage.getItem('branch')+"/"+e.target.value, {
            credentials: 'include',
            method: "POST",
            headers: {
                  "Content-Type": "application/json",
            },
            body: JSON.stringify(
                {
                "branch": branch,
                "doorlockType": e.target.value,
                "key": "",
                }
            )
            }).then(res => res.json())
            .then(json => {
                if (json.status=="success") {
                    window.alert("문을 열었습니다. (실제 도어락 작동시까지 조금 시간이 걸릴수 있습니다.)")
                } else {
                    window.alert(json.message)
                }
            }
        )
    }

    const getTemp = (e) => {
        fetch(process.env.REACT_APP_serviceUrl + "/admin/thermo-hygrometer/"+sessionStorage.getItem('branch'), {
                credentials: 'include',
                method: "GET",
                headers: {
                      "Content-Type": "application/json",
                },
                }).then(res => res.json())
                .then(json => {
                    if (json.status=="success") {
                        setTemp(json.data.infoList)
                        console.log(json.data.infoList)
                        setShowT(true)
                    } else {
                        window.alert(json.message)
                    }
                })
    }

    const getControl = (e) => {
        fetch(process.env.REACT_APP_serviceUrl + "/admin/airconditioner/"+sessionStorage.getItem('branch'), {
                credentials: 'include',
                method: "GET",
                headers: {
                      "Content-Type": "application/json",
                },
                }).then(res => res.json())
                .then(json => {
                    if (json.status=="success") {
                        setControl(json.data)
                        console.log(json.data)
                        setShowC(true)
                    } else {
                        window.alert(json.message)
                    }
                })
    }

    return (
        <>
            <div className="mypage-wrap">
                <div className="inner">
                    <div>
                        <div className="date">
                            <IoIosArrowBack style={{marginRight : 20}} onClick={subDate} />
                                {date.getMonth()+1}월 {date.getDate()}일
                            <IoIosArrowForward style={{marginLeft : 20}} onClick={addDate} />
                        </div>
                        <div className="time">
                            {hour-2>23 || hour-2<0? <div onClick={handleHour} id="-2" />:<div className="time-rec" id="-2" onClick={handleHour}>{hour-2}시</div>}
                            {hour-1>23 || hour-1<0? <div onClick={handleHour} id="-1" />:<div className="time-rec" id="-1" onClick={handleHour}>{hour-1}시</div>}
                            {hour>23 || hour<0? <div onClick={handleHour} /> : <div className="time-rec on" onClick={handleHour}>{hour}시</div>}
                            {hour+1>23 || hour+1<0? <div onClick={handleHour} id="+1" /> :<div className="time-rec" onClick={handleHour} id="+1">{hour+1}시</div>}
                            {hour+2>23 || hour+2<0? <div onClick={handleHour} id="+2" /> :<div className="time-rec" onClick={handleHour} id="+2">{hour+2}시</div>}
                        </div>
                        <BrowserView>
                            <div className="form-group right">
                                {RankList.map((rank, i)=>
                                    (<div>
                                        <label htmlFor="id" className={"rec-label right "+rank}>{rank} </label>
                                        <label htmlFor="id" className="sub-label right">{"10분: "+addComma(RankPrice[rank][10])+"원 / 30분: "+addComma(RankPrice[rank][30])+"원 / 1시간: "+addComma(RankPrice[rank][60])+"원"} </label>
                                    </div>)
                                )}
                            </div>
                        </BrowserView>
                        <MobileView>
                            <div className="form-group right bottom">
                                {RankList.map((rank, i)=>
                                    (<div>
                                        <label htmlFor="id" className={"rec-label mobile right "+rank}>{rank} </label>
                                        <label htmlFor="id" className="sub-label mobile right">{"10분: "+addComma(RankPrice[rank][10])+"원 / 30분: "+addComma(RankPrice[rank][30])+"원 / 1시간: "+addComma(RankPrice[rank][60])+"원"} </label>
                                    </div>)
                                )}
                            </div>
                        </MobileView>
                        {branchList.map((b, i)=>
                            <div className={branch==b? "line-rec up on": "line-rec up"} id={b} onClick={handleBranch}> {branchDict[b]} </div>
                         )}
                        <hr className="line" />
                        <div>
                            <button className="btn btn-green-quarter" onClick={handleShowD}>
                                도어락 제어
                            </button>
                            <button className="btn btn-green-quarter" onClick={getTemp}>
                                온습도 확인
                            </button>
                            <button className="btn btn-green-quarter" onClick={getControl}>
                                냉난방기 제어
                            </button>
                        </div>

                        <div>
                            <label htmlFor="coupon-box">
                                <input
                                  id="coupon-box"
                                  type="checkbox"
                                  checked = {check5}
                                  className="coupon-box"
                                  onChange={(event) => {
                                    setCheck10(false);
                                    setCheck15(false);
                                    setCheck20(false);
                                    if (check5==false){
                                        setPrice(initprice*0.95)
                                    } else {
                                        setPrice(initprice)
                                    }
                                    setCheck5(prev=>!prev);

                                   }}
                                />
                                <span> 5% 할인 </span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor="coupon-box">
                                <input
                                  id="coupon-box"
                                  type="checkbox"
                                  checked = {check10}
                                  className="coupon-box"
                                  onChange={(event) => {
                                    setCheck5(false);
                                    setCheck15(false);
                                    setCheck20(false);
                                    if (check10==false){
                                        setPrice(initprice*0.90)
                                    } else {
                                        setPrice(initprice)
                                    }
                                    setCheck10(prev=>!prev);
                                   }}
                                />
                                <span> 10% 할인 </span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor="coupon-box">
                                <input
                                  id="coupon-box"
                                  type="checkbox"
                                  checked = {check15}
                                  className="coupon-box"
                                  onChange={(event) => {
                                    setCheck5(false);
                                    setCheck10(false);
                                    setCheck20(false);
                                    if (check15==false){
                                        setPrice(initprice*0.85)
                                    } else {
                                        setPrice(initprice)
                                    }
                                    setCheck15(prev=>!prev);
                                   }}
                                />
                                <span> 15% 할인 </span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor="coupon-box">
                                <input
                                  id="coupon-box"
                                  type="checkbox"
                                  checked = {check20}
                                  className="coupon-box"
                                  onChange={(event) => {
                                    setCheck5(false);
                                    setCheck10(false);
                                    setCheck15(false);
                                    if (check20==false){
                                        setPrice(initprice*0.80)
                                    } else {
                                        setPrice(initprice)
                                    }
                                    setCheck20(prev=>!prev);
                                   }}
                                />
                                <span> 20% 할인 </span>
                            </label>
                        </div>
                        {/*<div className="card-list">
                            <button className="btn btn-green-mini">
                                F
                            </button>
                            {numList[branch].map((room)=>(
                                <button className="btn btn-green-mini">
                                    {room}
                                </button>
                            ))}
                        </div>*/}
                        {roomList[branch].map((n, i)=>(
                        <div className="form-group">
                            <div>
                                <label htmlFor="id" className={"rec-label "+RoomRank[branch][n]}>{RoomRank[branch][n]} </label>
                                <label htmlFor="id" className="label">{"ROOM "+n+" : "+RoomName[branch][n]}</label>
                                <label className="label mini" onClick={handleShow} id={n} >
                                    자세히
                                </label>
                                {/*<label htmlFor="id" className="label right">{"ROOM "+n+" : "+RoomName[branch][n]}</label>*/}
                                {/*<div>*/}
                                <div className="card-list">
                                    <div className="item">
                                        <label htmlFor="id" className="sub-label">{hour}시 </label>
                                        <div>
                                            {numList[branch].slice(0,6).map((num, idx)=>(
                                                <div className={((start<=(n+"_1_"+num)&&end>=(n+"_1_"+num))? "mini-rec click": (reserved["paidList"].includes(n+"_1_"+num)?"mini-rec red": (reserved["bookingList"].includes(n+"_1_"+num)?"mini-rec redLight": "mini-rec")))} id={n+"_1_"+num} onClick={handleSelect}/>
                                            ))}
                                        </div>
                                    </div>
                                    {numList[branch].slice(1,5).map((number, index)=>(
                                        <div className="item">
                                            <label htmlFor="id" className="sub-label">{hour+parseInt(number)-1}시 </label>
                                            <div>
                                                <div className={(start<=(n+"_"+number+"_1")&&end>=(n+"_"+number+"_1"))? "mini-rec blank click": (reserved["paidList"].includes(n+"_"+number+"_1")?"mini-rec blank red":(reserved["bookingList"].includes(n+"_"+number+"_1")?"mini-rec blank redLight":"mini-rec blank"))} id={n+"_"+number+"_1"} onClick={handleSelect} />
                                                {numList[branch].slice(1,6).map((num)=>(
                                                    <div className={(start<=(n+"_"+number+"_"+num)&&end>=(n+"_"+number+"_"+num))? "mini-rec click": (reserved["paidList"].includes(n+"_"+number+"_"+num)? "mini-rec red": (reserved["bookingList"].includes(n+"_"+number+"_"+num)? "mini-rec redLight": "mini-rec"))} id={n+"_"+number+"_"+num} onClick={handleSelect} />
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                    {/*<div className="item">
                                        <label htmlFor="id" className="sub-label">{hour+1}시 </label>
                                    </div>
                                    <div className="item">
                                        <label htmlFor="id" className="sub-label">{hour+2}시 </label>
                                    </div>
                                    <div className="item">
                                        <label htmlFor="id" className="sub-label">{hour+3}시 </label>
                                    </div>
                                    <div className="item">
                                        <label htmlFor="id" className="sub-label">{hour+4}시 </label>
                                    </div>*/}
                                </div>
                                <label htmlFor="id" className="margin"/>
                                {/* 맨처음 1시간 */}
                                {/*<div className="card-list">
                                    <div className="item">
                                        {numList.slice(0,6).map((num, idx)=>(
                                            <div className={day==0 && hourNow==hour && Math.ceil(today.getMinutes()/10)>idx? "mini-rec off":((start<=(n+"_1_"+num)&&end>=(n+"_1_"+num))? "mini-rec click": "mini-rec")} id={n+"_1_"+num} onClick={handleSelect}/>
                                        ))}
                                    </div>
                                </div>*/}
                                {/* 2-5시간 */}
                                {/*{numList.slice(1,5).map((number, index)=>(
                                        <>
                                            <div className={(start<=(n+"_"+number+"_1")&&end>=(n+"_"+number+"_1"))? "mini-rec blank click": "mini-rec blank"} id={n+"_"+number+"_1"} onClick={handleSelect} />
                                            {numList.slice(1,6).map((num)=>(
                                                <div className={(start<=(n+"_"+number+"_"+num)&&end>=(n+"_"+number+"_"+num))? "mini-rec click": "mini-rec"} id={n+"_"+number+"_"+num} onClick={handleSelect} />
                                            ))}
                                        </>
                                ))}*/}
                            </div>
                        </div>))}
                        <span className="calc-fixed">
                            <div>
                                예약장소 : <span className="calc highlight">{start=="9_9_9"?"" : ("Room "+start.split("_")[0])}</span>
                            </div>
                            <div>
                                예약일시 : {start=="9_9_9"? <span /> : <span className="calc highlight">2024년 {date.getMonth()+1}월 {date.getDate()}일 {hour+parseInt(start.split("_")[1])-1}:{start.split("_")[2]=="1"?"00":((parseInt(start.split("_")[2])-1)*10)} - {hour+parseInt(end.split("_")[1])+Math.floor(end.split("_")[2]/6)-1}:{end.split("_")[2]=="6"?"00":(parseInt(end.split("_")[2]))*10}</span>}
                            <div>
                            </div>
                                결제하실 금액 :
                                {price<0?
                                    <span />
                                    :
                                    <span className="calc highlight" style={{color : '#FF426F', textDecoration:"line-through", fontSize:"18px"}}>
                                        {price<0? "0": addComma(initprice)}원{" "}
                                    </span>
                                }
                                <span className="calc highlight">
                                    {/*{price<0? "0": addComma(Math.floor(price*(1-saleDict[branch]['percent'])*0.1)*10)}원*/}
                                    {price<0? "0": addComma(price)}원
                                </span>
                            </div>
                            <div>
                                {/*price<0?
                                    <span />
                                    :
                                    <span className="calc highlight" style={{color : '#7F7F93'}}>
                                        {"("+saleDict[branch]['reason']+")"}
                                    </span>
                                    */}
                            </div>
                            <div className="btn-wrap">
                                <button className="btn btn-black" onClick={nextStep}>다음으로</button>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <Modal className="modal-white" show={show} onHide={handleClose} centered animation={false} backdrop="static">
                <button className="btn-modal-close" onClick={handleClose}><i className="ico ico-modal-close" /></button>
                <div className="content">
                    <div className="title"><div className="form-group"><label htmlFor="id" className={"rec-label "+RoomRank[branch][room]}>{RoomRank[branch][room]} </label> {"ROOM "+room+" : "+RoomName[branch][room]}</div></div>
                    <div className="desc">{RoomExplain[branch][room]["desc"]}</div>
                    <div className="box">
                        <div className="val">
                            {/*상세 설명*/}
                            <div className="val">
                                <div className="items">
                                    마사지 세기 :
                                    {([...Array(parseInt(Math.floor(RoomExplain[branch][room]["strength"])))]).map((n, i) => <TbStar color="FFB137" />)}
                                    {([...Array(parseInt(Math.ceil(RoomExplain[branch][room]["strength"]%1)))]).map((n, i) => <TbStarHalf color="FFB137" />)}
                                    {/*{([...Array(parseInt(5-Math.ceil(RoomExplain[room]["strength"])))]).map((n, i) => <TbStar color="FFB137" />)}*/}
                                </div>
                                <div className="items">
                                    안마의자 크기 :
                                    {([...Array(parseInt(Math.floor(RoomExplain[branch][room]["size"])))]).map((n, i) => <TbStar color="FFB137" />)}
                                    {([...Array(parseInt(Math.ceil(RoomExplain[branch][room]["size"]%1)))]).map((n, i) => <TbStarHalf color="FFB137" />)}
                                    {/*{([...Array(parseInt(5-Math.ceil(RoomExplain[room]["size"])))]).map((n, i) => <TbStar color="FFB137" />)}*/}
                                </div>
                                <div className="items">
                                    특화 기능 : {RoomExplain[branch][room]["special"]}
                                </div>
                                <div className="items">
                                    그 외 특징 :
                                    {RoomExplain[branch][room]["etc"].map((line, idx)=>
                                    <div className="item">
                                        - {line}
                                    </div>
                                    )}
                                </div>
                                <div className="items">
                                    가격 :
                                    <div className="item">
                                        {" 10분: "+addComma(RankPrice[RoomRank[branch][room]][10])+"원 / 30분: "+addComma(RankPrice[RoomRank[branch][room]][30])+"원 / 1시간: "+addComma(RankPrice[RoomRank[branch][room]][60])+"원"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tip"><span className="color-false">*</span> 안마의자에 대한 느낌은 주관적일 수 있으니 선택시 참고만 해주세요.</div>
                    <div className="btn-wrap">
                        <button className="btn btn-black btn-black-border btn-lg btn-mo" onClick={handleClose}>닫기</button>
                    </div>
                </div>
            </Modal>
            {/* <Modal className="modal-red" show={showA} onHide={handleClose} centered animation={false} backdrop="static"> */}
            <Modal className="modal-red" show={showA} onHide={handleCloseA} centered animation={false} backdrop="static">
                <button className="btn-modal-close" onClick={handleCloseA}><i className="ico ico-modal-close-white" /></button>
                <div className="content">
                    <div className="title">예약내역</div>
                    {/*<div className="img">
                        <img src={process.env.PUBLIC_URL + '/img/common/img-mypage-2.svg'} alt="비등비딩은 회원님이 그리울 거예요!"/>
                    </div>*/}
                    {detail==null?
                    <div/>
                    :
                    detail.dataType == "paid"?
                        <>
                            <div className="txt">{"[결제완료]"}</div>
                            <div className="txt">{"예약자 : "+detail.paidName}</div>
                            <div className="txt">{"연락처 : "+detail.paidMobileNum+" / "+detail.paidEmail}</div>
                            <div className="txt">{"예약시간 : "+detail.paidStartDateTime+" – "+detail.paidEndDateTime}</div>
                            <div className="txt">{"예약장소 : "+detail.paidBranch+" - Room"+detail.paidRoom+" "+detail.paidMachine}</div>
                            <div className="txt">{"결제금액 : "+detail.paidTotalAmount+"원 / "+detail.paidMethod} </div>
                            <div className="txt"></div>
                            <BrowserView>
                                <div className="btn-wrap">
                                    <button className="btn btn-black width-50" onClick={reSendAlarm}>링크 재전송</button>
                                    <button className="btn btn-black width-50" onClick={deleteBlock}>예약취소(환불)</button>
                                </div>
                            </BrowserView>
                            <MobileView>
                                <div className="btn-wrap">
                                    <button className="btn btn-black" onClick={reSendAlarm}>링크 재전송</button>
                                </div>
                                <div className="btn-wrap">
                                    <button className="btn btn-black" onClick={deleteBlock}>예약취소(환불)</button>
                                </div>
                            </MobileView>
                            {showAcc?
                            <div>
                                <div className="form-group">
                                    <label htmlFor="name" className="label">이름</label>
                                    <input className="form-control" type="text" id="name" placeholder="성함을 입력해주세요." value={name} onChange={(e)=>{setName(e.target.value)}} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name" className="label">은행명</label>
                                    <Select options={BankCode} onChange={(e)=>{console.log(e); setBankCode(e.value)}}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="id" className="label">계좌번호</label>
                                    {/*<input className="form-control" type="number" id="number" value={code} onChange={(e)=>setCode(e.target.value)} placeholder="계좌번호를 입력해 주세요"/>*/}
                                    <input className="form-control" type="number" id="acc" placeholder="계좌번호를 입력해주세요." value={accNum} onChange={(e)=>setAccNum(e.target.value)} />
                                    <button className="btn btn-black" onClick={deleteBlock}>결제취소하기</button>
                                </div>
                            </div>
                            :
                            <div/>}
                            {showPhone?
                            <div className="form-group">
                                <label htmlFor="id" className="label">휴대폰번호</label>
                                <input className="form-control" type="number" id="phone" placeholder="휴대폰번호를 입력해주세요." value={phone} onChange={(e)=>setPhone(e.target.value)} />
                            </div>
                            : <div/> }

                        </>
                        :
                        <>
                            <div className="txt">{"[예약진행중] - "+(detail.bookingStatus=="beforePaymentTab"?"결제탭진입전":detail.bookingStatus=="paymentTab"?"결제진행중":"입금대기중")}</div>
                            <div className="txt">{"예약기한 : "+(Math.floor(detail.bookingTtl/60))+"분 "+(detail.bookingTtl-Math.floor(detail.bookingTtl/60)*60)+"초"}</div>
                            <div className="txt">{"예약자 : "+detail.bookingName}</div>
                            <div className="txt">{"연락처 : "+detail.bookingMobileNum+" / "+detail.bookingEmail}</div>
                            <div className="txt">{"예약시간 : "+detail.bookingStartDateTime+" – "+detail.bookingEndDateTime}</div>
                            <div className="txt">{"예약장소 : "+detail.bookingBranch+" - Room"+detail.bookingRoom+" "+detail.bookingMachine}</div>
                            <div className="txt">{"결제금액 : "+detail.bookingAmount+"원 "} </div>
                            {detail.bookingStatus=="waitingForDeposit"?
                                <div className="txt">{"입금계좌 : "+detail.bookingVirtualaccountBankName+" "+detail.bookingVirtualaccountAccountNumber} </div>
                                :<div/>
                            }

                            <div className="txt"></div>
                            <BrowserView>
                                <div className="btn-wrap">
                                    <button className="btn btn-black width-50" onClick={()=>window.alert("결제완료된 건에 대해서만 가능합니다.")}>링크 재전송</button>
                                    <button className="btn btn-black width-50" onClick={deleteBlock}>예약취소(환불)</button>
                                </div>
                            </BrowserView>
                            <MobileView>
                                <div className="btn-wrap">
                                    <button className="btn btn-black" onClick={()=>window.alert("결제완료된 건에 대해서만 가능합니다.")}>링크 재전송</button>
                                </div>
                                <div className="btn-wrap">
                                    <button className="btn btn-black" onClick={deleteBlock}>예약취소(환불)</button>
                                </div>
                            </MobileView>
                        </>
                    }
                </div>
            </Modal>
            <Modal className="modal-white" show={showD} onHide={handleCloseD} centered animation={false} backdrop="static">
                <button className="btn-modal-close" onClick={handleCloseD}><i className="ico ico-modal-close" /></button>
                <div className="content">
                    <div className="title">도어락 열기</div>
                    <div className="desc">관리자를 위한 출입문 해제 기능입니다.</div>
                    <div className="box">
                        <div className="val">
                            <div className="items bottom">
                                [외부 출입문]
                            </div>
                            <button className="btn btn-green-mini" value="front" onClick={openDoor}>
                                Front
                            </button>
                            <div className="items bottom">
                                [내부 출입문]
                            </div>
                            <div>
                                {numList[branch].map((room)=>(
                                    <button className="btn btn-green-mini" value={"room/"+room} onClick={openDoor}>
                                        {"Room "+room}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/*<div className="tip"><span className="color-false">*</span> 반영시 조금 시간이 걸릴 수 있으니 잠시 기다려주세요.</div>*/}
                    <div className="btn-wrap">
                        <button className="btn btn-black btn-black-border btn-lg btn-mo" onClick={handleCloseD}>닫기</button>
                    </div>
                </div>
            </Modal>
            <Modal className="modal-white" show={showT} onHide={handleCloseT} centered animation={false} backdrop="static">
                <button className="btn-modal-close" onClick={handleCloseT}><i className="ico ico-modal-close" /></button>
                <div className="content">
                    <div className="title">온/습도 확인하기</div>
                    <div className="desc">관리자를 위한 온도, 습도 확인 기능입니다.</div>
                    <div className="box">
                        <div className="val">
                            <div className="val">
                                {temp!=null?
                                <div className="items bottom">
                                    {temp.map((t)=>(
                                        <div>
                                            <div className="txt">{"["+t.location+"] : 온도 "+t.thermometer+"도 / 습도 "+t.hygrometer}</div>
                                        </div>
                                    ))}
                                </div>
                                    :
                                    <div />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tip"><span className="color-false">*</span> 반영시 조금 시간이 걸릴 수 있으니 잠시 기다려주세요.</div>
                    <div className="btn-wrap">
                        <button className="btn btn-black btn-black-border btn-lg btn-mo" onClick={handleCloseT}>닫기</button>
                    </div>
                </div>
            </Modal>
            <Modal className="modal-white" show={showC} onHide={handleCloseC} centered animation={false} backdrop="static">
                <BrowserView>
                    <button className="btn-modal-close" onClick={handleCloseC}><i className="ico ico-modal-close" /></button>
                    <div className="content">
                        <div className="title">에어컨 조절하기</div>
                        <div className="desc">관리자를 위한 냉난방기 제어 기능입니다.</div>
                        <div className="box">
                            <div className="val">
                                <div className="items bottom">
                                    {"["+title+"]"}
                                </div>
                                <div className="val">
                                    {control!=null?
                                        <div className="items bottom">
                                            {control.map((c, i)=>(
                                                <button className="btn btn-green-mini" value={i} onClick={handleDepth1}>
                                                    {c.name}
                                                </button>
                                            ))}
                                        </div>
                                            :
                                        <div />
                                    }
                                    {depth1!=null && control[depth1].children!=null?
                                        <div className="items bottom">
                                            {control[depth1]["children"].map((c, i)=>(
                                                <button className="btn btn-orange-mini-mini" value={i} onClick={handleDepth2}>
                                                    {c.name}
                                                </button>
                                            ))}
                                        </div>
                                            :
                                        <div />
                                    }
                                    {depth1!=null && depth2!=null && control[depth1]["children"][depth2]["children"]!=null?
                                        <div className="items bottom">
                                            {control[depth1]["children"][depth2]["children"].map((c, i)=>(
                                                <button className="btn btn-blue-mini-mini" value={i} onClick={handleDepth3}>
                                                    {c.name}
                                                </button>
                                            ))}
                                        </div>
                                            :
                                        <div />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="tip"><span className="color-false">*</span> 반영시 조금 시간이 걸릴 수 있으니 잠시 기다려주세요.</div>
                        <div className="btn-wrap">
                            <button className="btn btn-black btn-black-border btn-lg btn-mo" onClick={handleCloseC}>닫기</button>
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    <button className="btn-modal-close" onClick={handleCloseC}><i className="ico ico-modal-close" /></button>
                    <div className="content">
                        <div className="title">에어컨 조절하기</div>
                        <div className="desc">관리자를 위한 냉난방기 제어 기능입니다.</div>
                        <div className="box">
                            <div className="val">
                                <div className="items bottom">
                                    {"["+title+"]"}
                                </div>
                                <div className="val">
                                    {control!=null?
                                        <div className="card-list">
                                            <div className="items bottom">
                                                {control.map((c, i)=>(
                                                    <button className="btn btn-green-mini" value={i} onClick={handleDepth1}>
                                                        {c.name}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                            :
                                        <div />
                                    }
                                    <div className="items bottom-double" />
                                    {depth1!=null && control[depth1].children!=null?
                                        <div className="card-list">
                                            <div className="items bottom">
                                                {control[depth1]["children"].map((c, i)=>(
                                                    <button className="btn btn-orange-mini-mini" value={i} onClick={handleDepth2}>
                                                        {c.name}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                            :
                                        <div />
                                    }
                                    <div className="items bottom-double" />
                                    {depth1!=null && depth2!=null && control[depth1]["children"][depth2]["children"]!=null?
                                        <div className="card-list">
                                            <div className="items bottom">
                                                {control[depth1]["children"][depth2]["children"].map((c, i)=>(
                                                    <button className="btn btn-blue-mini-mini" value={i} onClick={handleDepth3}>
                                                        {c.name}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                            :
                                        <div />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="tip"><span className="color-false">*</span> 반영시 조금 시간이 걸릴 수 있으니 잠시 기다려주세요.</div>
                        <div className="btn-wrap">
                            <button className="btn btn-black btn-black-border btn-lg btn-mo" onClick={handleCloseC}>닫기</button>
                        </div>
                    </div>
                </MobileView>
            </Modal>
        </>
    );
};

export default Booking;