import React, { useState, useCallback, useEffect } from 'react';
import Header from '../Components/Header';
import Modal from 'react-bootstrap/Modal';
import MobileDetailMenu from "../Components/MobileDetailMenu";
import {Link} from "react-router-dom";
import { targetUrl } from "../config.js"
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RoomName, RankList, RankPrice, roomImgDict, RoomRank, RankColor, RoomExplain, rebranchDict } from "./config.js"
import { useLocation } from 'react-router-dom';

const CompletePay = () => {

  const location = useLocation();
  console.log(location)
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(location.state)
  }, []);
    return (
        <>
            <div className="mypage-wrap">
                <div className="inner">
                    <div className="complete">
                        <FaRegCheckCircle color="#0096C0" size={70} />
                        {data==null?
                            <div />
                            :
                            ((data.paymentMethod).includes("가상계좌")?
                            <div className="space">
                                가상계좌 발급이 완료되었습니다.
                                <div className="box">
                                    <div>
                                        {data.branch+"  Room "+data.roomNo}
                                        {"  Room "+data.roomNo}
                                    </div>
                                    <div>
                                        {RoomName[rebranchDict[data.branch]][data.roomNo]}
                                    </div>
                                    <div> - </div>
                                    <div>
                                        <b>{"예약시간"}</b>
                                    <div>
                                    </div>
                                        {data.startDateTime+" - "+data.endDateTime}
                                    </div>
                                    <div> - </div>
                                    <div>
                                        <b>{"환불가능시간"}</b>
                                    <div>
                                    </div>
                                        {data.refundableDateTime+"시까지"}
                                    </div>
                                    <div> - </div>
                                    <div>
                                        {data.virtualaccountBankName+" "+data.virtualaccountAccountNumber}
                                    </div>
                                    <div>
                                        {"("+data.virtualaccountDueDate+" 시까지)"}
                                    </div>
                                    <div> - </div>
                                        <b>{"결제필요금액"}</b>
                                    <div>
                                        {data.virtualaccountAmount}원
                                    </div>

                                    <div> - </div>
                                    <div>
                                        <div>
                                            <b>{"이메일 "}</b>
                                        </div>
                                        <div>
                                            {data.email}
                                        </div>
                                        <div>
                                            <b>{"휴대폰 번호 "}</b>
                                        </div>
                                        <div>
                                            {data.mobileNum}
                                        </div>
                                        {"위 계좌로 입금시 입력하신 연락처로 출입문 링크와 안마의자 작동법이 전송됩니다."}
                                    </div>
                                </div>
                            </div>
                            :
                             <div className="space">
                                결제가 완료되었습니다.
                                <div className="box">
                                    <div>
                                        <b>{data.branch+"  Room "+data.roomNo}</b>
                                    </div>
                                    <div>
                                        {RoomName[rebranchDict[data.branch]][data.roomNo]}
                                    </div>
                                    <div> - </div>
                                    <div>
                                        <b>{"예약시간"}</b>
                                    <div>
                                    </div>
                                        {data.startDateTime+" - "+data.endDateTime}
                                    </div>
                                    <div> - </div>
                                    <div>
                                        <b>{"환불가능시간"}</b>
                                    <div>
                                    </div>
                                        {data.refundableDateTime+"시까지"}
                                    </div>
                                    <div> - </div>
                                    <div>
                                        <div>
                                            <b>{"이메일 "}</b>
                                        </div>
                                        <div>
                                            {data.email}
                                        </div>
                                        <div>
                                            <b>{"휴대폰 번호 "}</b>
                                        </div>
                                        <div>
                                            {data.mobileNum}
                                        </div>
                                        {"위 연락처로 출입문 링크와 안마의자 작동법이 전송되었습니다."}
                                    </div>
                                </div>
                            </div>)
                        }

                    </ div>
                </div>
            </div>
        </>
    );
};

export default CompletePay;

